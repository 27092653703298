import React from 'react';

const Haulout = ({
  size,
  color = '#001A70',
  transform = 'none',
  onClick,
  cursor = 'pointer',
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    height={size}
    viewBox="0 0 32 32"
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
  >
    <path
      fill={color}
      d="M8.56521739,7.5 L8.565,7.828 L23.434,7.828 L23.4347826,7.5 L25,7.5 L25,24.5 L23.4347826,24.5 L23.434,12.011 L22.4052368,17.489637 L21.9811943,18.5542659 L21.7124218,19.2062179 C21.5473144,19.5989899 21.4103551,19.9080365 21.3015442,20.1333575 C20.9479086,20.8656511 20.152015,21.4723349 19.5706714,21.6363099 C18.9893277,21.8002848 17.5089928,21.8957913 16.0170132,22.2280729 L15.7376202,22.1684703 C14.3435035,21.8835855 13.0083647,21.7900364 12.4633551,21.6363099 C11.8820114,21.4723349 11.0861179,20.8656511 10.7324823,20.1333575 L10.6142886,19.880128 L10.4018127,19.3956268 C10.2979962,19.1523845 10.1816694,18.8719308 10.0528322,18.5542659 L9.62878968,17.489637 L8.565,11.796 L8.56521739,24.5 L7,24.5 L7,7.5 L8.56521739,7.5 Z M23.434,9.111 L8.565,9.111 L8.565,9.367 L10.0858733,17.3087232 L16.0170132,14.9851643 L21.9558733,17.3127232 L23.434,9.597 L23.434,9.111 Z M18.0586011,13.05706 L18.0581976,13.38506 L19.1728928,13.3851056 L20.4878526,16.3260728 L19.2236673,15.8304751 L18.5275858,14.2736944 L13.5744644,14.2736944 L12.8783829,15.8304751 L11.6141976,16.3260728 L12.9291574,13.3851056 L13.9751976,13.38506 L13.9754253,13.05706 L18.0586011,13.05706 Z"
    ></path>
  </svg>
);

Haulout.defaultProps = {
  color: '#001A70',
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default Haulout;
