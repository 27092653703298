import React from 'react';

const Forklift = ({
  size,
  color = '#001A70',
  transform = 'none',
  onClick,
  cursor = 'pointer',
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    height={size}
    viewBox="0 0 32 32"
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
  >
    <path
      fill={color}
      d="M10.6,20.8 C11.4843752,20.8 12.2,21.5156248 12.2,22.4 C12.2,23.2843752 11.4843752,24 10.6,24 C9.7156248,24 9,23.2843752 9,22.4 C9,21.5156248 9.7156248,20.8 10.6,20.8 Z M17.8,20.8 C18.6843752,20.8 19.4,21.5156248 19.4,22.4 C19.4,23.2843752 18.6843752,24 17.8,24 C16.9156248,24 16.2,23.2843752 16.2,22.4 C16.2,21.5156248 16.9156248,20.8 17.8,20.8 Z M19.15,8 L22.35,20.8 L25,20.8 L25,22.4 L21.15,22.4 L17.55,8 L19.15,8 Z M13.65,8.8 C14.4579165,8.8 15.2168333,9.19472205 15.6396463,9.89524071 L15.725,10.05 L18.925,16.525 C19.1902342,17.0554691 19.2975586,17.6002934 19.3202454,18.1092286 L19.325,18.35 L19.4,18.4 L19.4,20.65 C19,20.25 18.4406248,20 17.8,20 C16.4972654,20 15.4872721,20.9557127 15.4053677,22.2317905 L15.4,22.4 L13,22.4 C13,21.0406248 11.9593752,20 10.6,20 C10.0234377,20 9.51268754,20.2025 9.12470313,20.5346 L9,20.65 L9,10.4 C9,9.9812504 9.1468752,9.5531248 9.45,9.25 C9.7152342,8.9847658 10.0761721,8.8391602 10.4427919,8.80690308 L10.6,8.8 L13.65,8.8 Z M13.65,10.4 L10.6,10.4 L10.6,16 L12.35,16 C12.7,16 13.0547855,16.1196292 13.3515504,16.3630738 L13.475,16.475 L14.6,17.6 L17.6,17.6 C17.575,17.5229168 17.5541668,17.4444444 17.5254631,17.3664351 L17.475,17.25 L17.475,17.225 L14.275,10.75 C14.1937504,10.5875 13.9062504,10.4 13.65,10.4 Z"
    ></path>
  </svg>
);

Forklift.defaultProps = {
  color: '#001A70',
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default Forklift;
