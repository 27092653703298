import React from 'react';

const Buoy = ({
  size,
  color = '#001A70',
  transform = 'none',
  onClick,
  cursor = 'pointer',
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    height={size}
    viewBox="0 0 32 32"
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
  >
    <path
      fill={color}
      d="M15.9998639,6 C16.9971185,6 17.8056386,6.66412576 17.8055842,7.48347961 C17.8055842,8.02008502 17.4555581,8.48556445 16.9356871,8.74551211 L16.8022852,8.80613934 L16.8022852,10.1764411 L17.0235198,10.3158603 C18.4424604,11.2269581 22,13.8288426 22,17.0162296 C22,20.295033 18.2362167,22.9540599 16.9072624,23.790596 L16.8022852,23.8560628 L16.8022852,25.340795 C16.8022852,25.7045745 16.4431668,26 15.9998639,26 C15.5935028,26 15.2578344,25.7517981 15.2047117,25.4301938 L15.1973881,25.340795 L15.1973881,23.8560628 L14.8494204,23.6345046 C13.3568339,22.6566995 10,20.1128773 10,17.0162296 C10,13.7377744 13.7634224,11.078757 15.0924074,10.2419327 L15.1973881,10.1764411 L15.1973881,8.8060946 C14.6048835,8.56260568 14.1940891,8.06475741 14.1940891,7.48343488 C14.1940891,6.66412576 15.0025003,6 15.9998639,6 Z M20.3947217,17.0162743 L11.6053328,17.0162743 C11.6053328,19.6223171 14.7953061,21.9224217 15.9998639,22.6980702 L16.2477072,22.5350688 C17.5458865,21.6637588 20.2094071,19.6029766 20.3855327,17.2596574 L20.3947217,17.0162743 Z"
    ></path>
  </svg>
);

Buoy.defaultProps = {
  color: '#001A70',
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default Buoy;
