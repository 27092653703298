import React from 'react';

const WaveOverBar = ({
  size,
  color = '#001A70',
  transform = 'none',
  onClick,
  cursor = 'pointer',
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    height={size}
    viewBox="0 0 32 32"
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
  >
    <path
      fill={color}
      d="M25,18.99574 L25,21 L7,21 L7,18.99574 L25,18.99574 Z M19.1006969,9 C23.1182594,9 22.4965094,11.8414803 22.4965094,11.8414803 C19.1963219,9.99126818 16.8049469,15.0133833 22.5312906,15.0133833 L24.4486056,15.0059683 L24.9176969,15.0051076 L24.9176969,16.9562655 L7.00000938,16.9562655 L7.00000938,15.0133833 C11.5437906,15.0133833 13.9351656,9 19.1006969,9 Z"
    ></path>
  </svg>
);

WaveOverBar.defaultProps = {
  color: '#001A70',
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default WaveOverBar;
