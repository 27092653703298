import React from 'react';

const Bollard = ({
  size,
  color = '#001A70',
  transform = 'none',
  onClick,
  cursor = 'pointer',
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    height={size}
    viewBox="0 0 32 32"
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
  >
    <path
      fill={color}
      d="M20.307727,21.393125 L20.307727,24 L11.6923375,24 L11.6923375,22.8409063 C11.8252466,22.8635313 11.962284,22.8755938 12.102353,22.8755938 C12.1939477,22.8755938 12.2862592,22.8704271 12.3781215,22.8601956 L12.5154324,22.8410625 L20.307727,21.393125 Z M19.7126192,18.5264688 C20.013237,18.4768438 20.3128228,18.5257813 20.5567422,18.6643438 C20.7981782,18.8015313 20.9616297,19.020125 21.0051372,19.2639375 C21.0899634,19.7395875 20.7199631,20.2075881 20.1654829,20.3540815 L20.0439406,20.3804375 L12.3274374,21.8135938 C12.2526135,21.825875 12.1769511,21.8320938 12.102353,21.8320938 C11.5616537,21.8320938 11.1121944,21.5141875 11.0336615,21.076125 C10.9899283,20.832125 11.0679773,20.5728125 11.2477481,20.36475 C11.3990891,20.1896198 11.6086847,20.0604531 11.84824,19.9925495 L11.9953419,19.9592813 L19.7126192,18.5264688 Z M19.7125869,15.054375 C20.0447146,14.999375 20.3792613,15.0664688 20.6313081,15.2384688 C20.8333648,15.3764688 20.9661127,15.5729375 21.0050727,15.7915938 C21.089929,16.2673604 20.7199307,16.7353688 20.1654244,16.8817356 L20.0438761,16.9080625 L12.3275019,18.3414063 C12.252678,18.3536875 12.1769189,18.3599375 12.1022562,18.3599375 C11.5616214,18.3599375 11.1121621,18.0419375 11.0336293,17.60375 C10.989896,17.35975 11.0679451,17.1004688 11.2477481,16.8924688 C11.3990085,16.7173906 11.6086354,16.5882326 11.8482407,16.5203304 L11.9953742,16.4870625 L19.7125869,15.054375 Z M20.2717987,8 C21.7761135,8 23,9.185875 23,10.6434688 C23,12.0337933 21.886369,13.1768857 20.4780546,13.2794279 L20.3076948,13.2866875 L20.3076948,14.0206563 C20.1157086,13.9914766 19.9183889,13.9847617 19.7209739,14.001896 L19.5237211,14.0270313 L11.7480563,15.4706094 L11.7480563,15.4706094 L11.6923052,15.4822813 L11.6923052,13.2866875 C10.2045033,13.2679688 9,12.0894063 9,10.6434688 C9,9.2419363 10.1315221,8.0916231 11.5559213,8.00521075 L11.7282013,8 L20.2717987,8 Z"
    ></path>
  </svg>
);

Bollard.defaultProps = {
  color: '#001A70',
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default Bollard;
